// extracted by mini-css-extract-plugin
export var aaron = "Footer-module--aaron--aJaN3";
export var creditIcon = "Footer-module--credit-icon--Be2jS";
export var credits = "Footer-module--credits--KIN4O";
export var fadingIn = "Footer-module--fading-in--Dmz5v";
export var fadingOut = "Footer-module--fading-out--PMAND";
export var focus = "Footer-module--focus--IQUTZ";
export var footer = "Footer-module--footer--rLNBC";
export var footerCredits = "Footer-module--footer-credits--89YHV";
export var footerNav = "Footer-module--footer-nav--YcsIY";
export var github = "Footer-module--github--gpqM3";
export var heart = "Footer-module--heart--hO6wI";
export var linkedin = "Footer-module--linkedin--vgWiq";
export var palette = "Footer-module--palette--DFQIa";
export var socialIcon = "Footer-module--social-icon--cwNNM";
export var socialLink = "Footer-module--social-link--d7QlC";
export var turkeyLogo = "Footer-module--turkey-logo--H4Q+E";
export var twitter = "Footer-module--twitter--yIY+J";