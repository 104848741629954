import React from 'react';

const GetInTouchIcon = () => {
  return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 92 92"
		>
			<g id="Group_16" transform="translate(0)">
				<g id="Group_12">
					<path
						id="Path_41"
						className="st0"
						fill="#f5a9b8"
						d="M7.6,41.5c-2.6-0.6-3.7-2.6-3.1-5.2s2.4-3.6,4.5-3.1l0.2,0l-0.4,1.9l-0.2,0
          c-1-0.3-2,0.3-2.3,1.3c0,0.1-0.1,0.2-0.1,0.3c-0.4,1.2,0.3,2.4,1.5,2.8c0.1,0,0.2,0.1,0.3,0.1l2.5,0.6c1.2,0.4,2.4-0.3,2.8-1.5
          c0-0.1,0.1-0.2,0.1-0.3c0.4-1-0.1-2.1-1.1-2.5c-0.1,0-0.2-0.1-0.4-0.1l-0.1,0l-0.6,2.5l-1.6-0.4l1-4.4l1.5,0.3
          c2.4,0.6,3.6,2.4,3,4.9s-2.4,3.9-5.1,3.3L7.6,41.5z"
					/>
					<path
						id="Path_42"
						className="st0"
						fill="#f5a9b8"
						d="M10.5,22.5l1.6,0.9l-2.4,4.3l2.2,1.2l2.2-4l1.6,0.9l-2.2,4l2.3,1.2l2.4-4.4l1.6,0.9l-3.3,6.1
          l-9.2-5L10.5,22.5z"
					/>
					<path
						id="Path_43"
						className="st0"
						fill="#f5a9b8"
						d="M18.4,13.5l1.3,1.3l-2.2,2.3l6.3,6l-1.4,1.4l-6.3-6l-2.2,2.3l-1.3-1.2L18.4,13.5z"
					/>
					<path
						id="Path_44"
						className="st0"
						fill="#f5a9b8"
						d="M27.9,7.6l4.7,9.4l-1.8,0.9l-4.7-9.4L27.9,7.6z"
					/>
					<path
						id="Path_45"
						className="st0"
						fill="#f5a9b8"
						d="M34.8,5.1l4.6,7.6l0.1,0l-2.1-8.2l1.9-0.5l2.6,10.2l-3.3,0.9L34,7.3l-0.1,0l2.1,8.2l-1.9,0.5
          L31.5,5.9L34.8,5.1z"
					/>
					<path
						id="Path_46"
						className="st0"
						fill="#f5a9b8"
						d="M57.5,4.9l-0.3,1.8L54,6.2l-1.5,8.6l-2-0.3l1.5-8.6l-3.1-0.5l0.3-1.8L57.5,4.9z"
					/>
					<path
						id="Path_47"
						className="st0"
						fill="#f5a9b8"
						d="M64.8,7.7c2.4,1.2,3.2,3.3,2,5.7l-1.1,2.3c-1.2,2.4-3.4,3-5.7,1.9s-3.2-3.3-2-5.7l1.1-2.3
          C60.3,7.2,62.4,6.6,64.8,7.7z M59.7,12.8c-0.6,1.1-0.2,2.6,1,3.2c1.1,0.6,2.6,0.2,3.2-1c0,0,0-0.1,0-0.1l1.2-2.4
          c0.6-1.1,0.1-2.6-1-3.2c-1.1-0.6-2.6-0.1-3.2,1c0,0,0,0.1,0,0.1L59.7,12.8z"
					/>
					<path
						id="Path_48"
						className="st0"
						fill="#f5a9b8"
						d="M73.5,13.6l-4.7,5c-0.8,0.9-0.7,2.2,0.2,3c0.8,0.7,2.1,0.7,2.9-0.1l4.7-5l1.4,1.4l-4.7,5
          c-1.6,1.8-3.8,1.8-5.7,0s-2-3.9-0.3-5.7l4.7-5L73.5,13.6z"
					/>
					<path
						id="Path_49"
						className="st0"
						fill="#f5a9b8"
						d="M77.2,23.2c2.3-1.3,4.5-0.6,5.8,1.6s0.8,4.4-1.2,5.5l-0.2,0.1l-1-1.7l0.3-0.1
          c1-0.5,1.3-1.8,0.7-2.7c0-0.1-0.1-0.1-0.1-0.2c-0.5-1.1-1.9-1.5-3-1c-0.1,0.1-0.2,0.1-0.3,0.2L76,26.2c-1.1,0.5-1.6,1.8-1.1,2.9
          c0,0.1,0.1,0.2,0.2,0.3c0.4,1,1.6,1.5,2.6,1c0.1,0,0.1-0.1,0.2-0.1l0.4-0.2l1,1.7L78.9,32c-2,1.1-4,0.6-5.4-1.7s-0.9-4.5,1.5-5.8
          L77.2,23.2z"
					/>
					<path
						id="Path_50"
						className="st0"
						fill="#f5a9b8"
						d="M86.9,34.4l-4.2,1l0.9,3.9l4.2-1l0.4,1.9L78,42.6l-0.4-1.9l4.3-1l-0.9-3.9l-4.3,1l-0.4-1.9
          l10.2-2.4L86.9,34.4z"
					/>
				</g>
				<g id="Group_14">
					<path
						id="Path_51"
						className="st0"
						fill="#f5a9b8"
						d="M84.4,50.5c2.6,0.6,3.7,2.6,3.1,5.2s-2.4,3.6-4.5,3.1l-0.2,0l0.4-1.9l0.2,0c1,0.3,2-0.3,2.3-1.3
          c0-0.1,0.1-0.2,0.1-0.3c0.4-1.2-0.3-2.4-1.5-2.8c-0.1,0-0.2-0.1-0.3-0.1l-2.5-0.6c-1.2-0.4-2.4,0.3-2.8,1.5c0,0.1-0.1,0.2-0.1,0.3
          c-0.4,1,0.1,2.1,1.1,2.5c0.1,0,0.2,0.1,0.4,0.1l0.1,0l0.6-2.5l1.6,0.4l-1,4.4l-1.5-0.3c-2.4-0.6-3.6-2.4-3-4.9s2.4-3.9,5.1-3.3
          L84.4,50.5z"
					/>
					<path
						id="Path_52"
						className="st0"
						fill="#f5a9b8"
						d="M81.5,69.5l-1.6-0.9l2.4-4.3l-2.2-1.2l-2.2,4l-1.6-0.9l2.2-4L76.2,61l-2.4,4.4l-1.6-0.9l3.3-6.1
          l9.2,5L81.5,69.5z"
					/>
					<path
						id="Path_53"
						className="st0"
						fill="#f5a9b8"
						d="M73.6,78.5l-1.3-1.3l2.2-2.3l-6.3-6l1.4-1.4l6.3,6l2.2-2.3l1.3,1.3L73.6,78.5z"
					/>
					<path
						id="Path_54"
						className="st0"
						fill="#f5a9b8"
						d="M64.1,84.4L59.4,75l1.8-0.9l4.7,9.4L64.1,84.4z"
					/>
					<path
						id="Path_55"
						className="st0"
						fill="#f5a9b8"
						d="M57.2,86.9l-4.6-7.6l-0.1,0l2.1,8.2l-1.9,0.5l-2.6-10.2l3.3-0.9l4.6,7.6l0.1,0L56,76.4l1.9-0.5
          l2.6,10.2L57.2,86.9z"
					/>
					<path
						id="Path_56"
						className="st0"
						fill="#f5a9b8"
						d="M34.5,87.1l0.3-1.8l3.1,0.5l1.5-8.6l2,0.3l-1.5,8.6l3.1,0.5l-0.3,1.8L34.5,87.1z"
					/>
					<path
						id="Path_57"
						className="st0"
						fill="#f5a9b8"
						d="M27.2,84.3c-2.4-1.2-3.2-3.3-2-5.7l1.1-2.3c1.2-2.4,3.4-3,5.7-1.9s3.2,3.3,2,5.7l-1.1,2.3
          C31.7,84.8,29.6,85.4,27.2,84.3z M32.3,79.2c0.6-1.1,0.2-2.6-1-3.2c-1.1-0.6-2.6-0.2-3.2,1c0,0,0,0.1,0,0.1l-1.2,2.4
          c-0.6,1.1-0.1,2.6,1,3.2c1.1,0.6,2.6,0.1,3.2-1c0,0,0-0.1,0-0.1L32.3,79.2z"
					/>
					<path
						id="Path_58"
						className="st0"
						fill="#f5a9b8"
						d="M18.5,78.4l4.7-5c0.8-0.9,0.7-2.2-0.2-3c-0.8-0.7-2.1-0.7-2.9,0.1l-4.7,5L14,74.1l4.7-5
          c1.6-1.8,3.8-1.8,5.7,0s2,3.9,0.3,5.7l-4.7,5L18.5,78.4z"
					/>
					<path
						id="Path_59"
						className="st0"
						fill="#f5a9b8"
						d="M14.8,68.8c-2.3,1.3-4.5,0.6-5.8-1.6s-0.8-4.4,1.2-5.5l0.2-0.1l1,1.7l-0.3,0.1
          c-1,0.5-1.3,1.8-0.7,2.7c0,0.1,0.1,0.1,0.1,0.2c0.5,1.1,1.9,1.5,3,1c0.1-0.1,0.2-0.1,0.3-0.2l2.2-1.3c1.1-0.5,1.6-1.8,1.1-2.9
          c0-0.1-0.1-0.2-0.2-0.3c-0.4-1-1.6-1.5-2.6-1c-0.1,0-0.1,0.1-0.2,0.1l-0.4,0.2l-1-1.7l0.3-0.2c2-1.1,4-0.6,5.4,1.7
          s0.9,4.5-1.5,5.8L14.8,68.8z"
					/>
					<path
						id="Path_60"
						className="st0"
						fill="#f5a9b8"
						d="M5.1,57.6l4.2-1l-0.9-3.9l-4.2,1l-0.4-1.9L14,49.4l0.4,1.9l-4.3,1l0.9,3.9l4.3-1l0.4,1.9
            L5.6,59.5L5.1,57.6z"
					/>
				</g>
			</g>
		</svg>
	);
};

export default GetInTouchIcon;
